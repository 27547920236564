.container {
    margin-top: 150px;
    height: 528px;
    display: flex;
}

.imgBlock {
    height: 100%;
    background-image: url("../../../assets/img/advantages.png");
    background-position: center;
    background-size: cover ;
    background-repeat: no-repeat;
    width: 50%;
}

.descBlock {
    background: #DF853B;
    width: 100%;
    padding: 90px 0 90px 100px;

}

.title {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
    color: #F6F6F6;
    margin-bottom: 60px;
}

.lineBlock {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.text {
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #F6F6F6;
    margin-left: 15px;
}

.line {
    display: flex;
}

.imgBlockMobile {
    display: none;
}



@media only screen and (max-width: 1440px) {
    .container {
        margin-top: 100px;
    }

    .descBlock {
        background: #DF853B;
        width: 100%;
        padding:50px 80px;
        z-index: 5;
    }

    .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        color: #F6F6F6;
        margin-bottom: 20px;
    }

    .text {
        font-size: 15px;
    }
}





@media only screen and (max-width: 1100px) {

    .container {
        margin-top: 50px;
        height: 100%;
    }

    .imgBlock{
        display: none;
    }

}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .imgBlock {
        display: none;
    }

    .descBlock {
        padding: 0 16px 64px;
        height: fit-content;
    }
    .title {
        font-weight: 700;
        font-size: 27px;
        line-height: 34px;
        margin-top: 64px;
        margin-bottom: 32px;
        padding-top: 64px;
        padding-left: 16px;
    }
    .container {
        height: auto;
        display: block;
    }
    .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-left: 12px;
    }
    .imgBlockMobile {
        display: block;
        width: 100%;
        height:132px;
        background-image: url("../../../assets/img/advantages.png");
        background-position: center bottom;
        background-size: cover ;
        background-repeat: no-repeat;

    }


    .cardBlock {

        padding-left: 16px;
        padding-right: 16px;
    }
}
