.wrapper {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.content {
    width: 100%;
    background-color: #F6F4F2;
}

.title {
    font-family: 'Cera Pro', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 44px;
    color: #3F3F3F;
    margin-bottom: 48px;
    padding: 0;
}

.select_title {
    font-family: 'Cera Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3F3F3F;
    margin-bottom: 15px;
}

.tables_nav_wrapper {
    margin-top: 40px;
    display: flex;
    padding: 0;

}

.unactive {
    background: #EDEDED;
    color: #ABABAB;
    font-size: 16px;
    margin-right: 20px;
}

.active {
    background: #DF853B;
    color: #F6F6F6;
    font-size: 16px;
    margin-right: 20px;
}

.table_wrapper {
    position: relative;
}


.select_block {
    padding: 0 16px;
}


@media only screen and (min-device-width: 625px) and (max-device-width: 790px) {
    .content {
        width: 100%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 624px) {
    .wrapper {
        max-width: 100%;
    }

    .content {
        width: 100%;
    }

    .title {
        font-size: 20px;
        line-height: 26px;
        padding-left: 0;
        margin-bottom: 24px;
    }

    .select_title {
        width: 100%;
        text-align: left;
    }

    .select_block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 16px;

    }


    .tables_nav_wrapper {
        overflow-x: auto;
        padding: 0 16px;
    }

    .tables_nav_wrapper::-webkit-scrollbar {
        display: none;
    }


    .tables_nav_wrapper {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        margin-top: 0;
        padding-left: 0;
    }

}

@media print {
    .tables_nav_wrapper,
    .select_block,
    .title,
    .statementWrapper {
        display: none;
    }
}


.statementWrapper {
    position: absolute;
    right: 15px;
    top: -35px;
}