.input {
    background: #FFFFFF;
    border-radius: 22px;
    border: 1px solid #D9D9D9;
    min-width: 288px;
    padding: 13px 16px;
    width: 100%;
    box-sizing: border-box;
}

.error {
    border: 1px solid #EB5757;
}

input:focus {
    outline: none;
    border-color:  #F2CEB1 !important;
}

.inputWrapper {
    width: 400px;
}

