.button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    border: none;
    background-color: #EDEDED;
}

.secondaryBtn {
    background-color: #DF853B;
}

.icon {
    width: 20px;
    height: 20px;

}
