.accountCardWrapper {
    width: calc(50% - 10px);
    height: auto;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 35px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Cera Pro', sans-serif;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 23px;
}


.testAcc {
    background: url("../../../../assets/img/Test.svg") 100% center no-repeat;
}


.titleWrapper {
    width: 100%;
    margin-bottom: 30px;
}

.title {
    font-style: normal;
    font-weight: 400;
    color: #ABABAB;
    margin-bottom: 10px;
}

.acc_number {
    font-style: normal;
    font-weight: 700;
    color: #DF853B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
}

.copyIcon {
    cursor: pointer;
}

.credits {
    font-style: normal;
    font-weight: 400;
    color: #3F3F3F;
}

.total_wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.right {
    min-width: 126px;
}

.noAccounts {
    height: 60vh;
    width: 100%;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: #797979;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.withdraw_btn_block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.unactive {
    background: #EDEDED;
    color: #ABABAB;
    font-size: 18px;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {

    .accountCardWrapper {
        width: 100%;
        padding: 16px;
        font-size: 14px;
        line-height: 18px;
    }

    .titleWrapper {
        margin-bottom: 8px;
    }

    .title {
        margin-bottom: 0;
    }

    .total_wrap {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-bottom: 16px;
        gap: 8px;
    }


}
