.container {
    background: #F6F6F6;
    position: relative;
    z-index: 100;
    overflow-x: hidden;
}

.container::before {
    position: absolute;
    content: url('./assets/img/bg_gr_one.png');
    right: 0;
    bottom: 400px;
    display: block;
}

.container::after {
    position: absolute;
    content: url('./assets/img/bg_gr_two.png');
    left: 0;
    bottom: 200px;
    display: block;
}


@media only screen and (max-width: 640px) and (min-width: 320px) {

    .container::before {
        position: absolute;
        content: url('./assets/img/main_section_bg_left.png');
        left: 0;
        top: 0;
        display: block;
    }

    .container::after {
        display: none;
    }


}






