.container {
    display: flex;
    flex-direction: column;
    width: 63%;
    margin: 150px auto;
    height: 100%;
    position: relative;
}


.title {
    font-family: 'Cera Pro', sans-serif;
    font-weight: 700;
    font-size: 52px;
    line-height: 65px;
    color: #3F3F3F;
    margin-bottom: 60px;


}

.labelTitle {
    font-family: 'Cera Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3F3F3F;
    margin-bottom: 15px;
}


.formWrapper {
    display: grid;
    grid-template-rows: 93px 93px auto auto;
    grid-template-columns: 400px 400px;
    grid-template-areas:
    "company website"
    "email  mobilePhone"
    "message  message"
    "submit submit";
    row-gap: 35px;
    column-gap: 70px;
}

.message {
    grid-area: message;
}

.message textarea {
    min-width: 288px;
    max-width: 870px;
    min-height: 155px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: none;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    font-family: 'Cera Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 17px 25px;
    box-sizing: border-box;
    resize: none;
}

.message textarea::placeholder {
    color: #C5C5C5;
}


.website {
    grid-area: website;
    position: relative;
}

.mobilePhone {
    grid-area: mobilePhone;
    position: relative;
}

.email {
    grid-area: email;
    position: relative;
}

.company {
    grid-area: company;
    position: relative;
}

.submitButton {
    margin-top: 70px;
    grid-area: submit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error {
    position: absolute;
    left: 25px;
    font-family: 'Cera Pro', sans-serif;
    top: 94px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #EB5757;
}

.successMessage {
    font-family: 'Cera Pro', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    text-transform: uppercase;
    color: #71B48D;
}

.fail {
    color: #EB5757;
}


@media only screen and (max-width: 1440px) {
    .container {
        margin: 60px auto;
        width: 100%;
        align-items: center;
    }


}


@media only screen and (max-width: 890px) {

    .formWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    .message textarea {
        max-width: 870px;
        min-height: 155px;
        min-width: 350px;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        border: none;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
        font-family: 'Cera Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 17px 25px;
        box-sizing: border-box;
        resize: none;
    }
}


@media only screen and (max-width: 640px) and (min-width: 320px) {

    .title {
        font-size: 27px;
        line-height: 34px;
        text-align: left;
        width: 100%;
        padding-left: 16px;
        margin-bottom: 32px;
    }

    .submitButton {
        margin-top: 0;
        width: 100%;
    }

    .submitButton > div {
        width: 100%;
    }

}


