.nav {
    display: flex;
    justify-content: center;
    gap: 50px;
    font-family: 'Cera Pro';
}

.link {
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

a {
    text-decoration: none;
}

.link:hover {
    color: #EB6D35;
    cursor: pointer;
}


.active {
    color: #EB6D35;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

}

@media only screen
and (max-device-width: 881px) {
    .nav {
        gap: 20px;
    }
}




@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .nav {
        display: flex;
        gap: 20px;
        margin-left: 20px;
    }

    .link {
        font-size: 14px;
        line-height: 17px;
    }

    .active {
        font-size: 14px;
        line-height: 17px;
    }

    a {

        font-size: 14px;
        line-height: 17px;

    }

    .buttonWrapper {
        display: block;
        z-index: 100;
    }
}

