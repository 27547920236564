.wrapper {
    max-width: 450px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 70px 40px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
    font-family: 'Cera Pro', sans-serif;
    font-style: normal;
}


.content {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
}

.addressLine {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 100%;
}

.address {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.icon {
    cursor: pointer;
}

.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.icon path,
.closeIcon path {
    transition: fill .3s ease;
}

.icon:hover path,
.closeIcon:hover path {
    fill: #DF853B;
}

.qr {
    width: 248px;
    height: 248px;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .wrapper {
        max-width: 288px;
        border-radius: 20px;
        padding: 52px 16px 24px;
    }

    .content {
        gap: 16px;
    }

    .closeIcon {
        top: 16px;
        right: 16px;
    }

    .title {
        font-size: 20px;
    }

    .address {
        font-size: 14px;
    }

    .qr {
        width: 180px;
        height: 180px;
    }

    .icon {
       width: 16px;
        height: 16px;
    }

}


