.mainContainer {
    width: 58%;
    display: flex;
    margin: 0 auto;
    padding-top: 64px;
    z-index: 2;
}

.descBlock {
    width: 50%;
    padding-top: 114px;
}

.imgBlock {
    width: 50%;
}

.imgBlock img {
    width: 750px;
}

.title {
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 64px;
    line-height: 80px;
    color: #3F3F3F;
    margin-bottom: 40px;
}

.bold {
    font-weight: 900;
}

.subtitle {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #797979;
}

.line {
    border: 2px solid #3F3F3F;
    width: 79px;
    margin-right: 20px;
}

.subtitleBlock {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
}

.buttonWrapper {
    display: none;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 630px) {
    .imgBlock {
        display: none;
    }

    .mainContainer {
        margin: 0;
        width: initial;
        padding: 0 16px 129px 16px;
        position: relative;
    }

    .mainContainer::before {
        position: absolute;
        content: url('../../../assets/img/main_section_bg_right.png');
        right: 0;
        top: 0;
        display: block;
        z-index: -100;
    }

    .descBlock {
        width: 100%;
    }

    .title {
        font-size: 34px;
        line-height: 43px;
        margin-bottom: 24px;
    }

    .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    .line {
        display: none;
    }

    .subtitleBlock {
        margin-bottom: 32px;
    }
}

@media only screen and (max-width: 1440px) {

    .mainContainer {
        padding-top: 50px;
        align-items: center;
    }


    .title {
        font-weight: 400;
        font-size: 43px;
        line-height: 51px;
        margin-bottom: 30px;
    }


    .imgBlock {
        width: 50%;
    }

    .imgBlock img {
        width: 350px;
    }


}


@media only screen and (max-width: 1100px) {

    .mainContainer {
        padding-top: 50px;
        align-items: center;
    }

    .title {
        font-weight: 400;
        font-size: 43px;
        line-height: 51px;
        margin-bottom: 30px;
    }

    .imgBlock {
        display: none;
    }

    .descBlock {
        width: 100%;
        padding-top: 114px;
    }

}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .descBlock {
        padding-top: 107px;
    }


    .title {
        font-size: 34px;
        line-height: 43px;
        margin-bottom: 24px;
    }


    .buttonWrapper {
        margin-top: 8px;
        display: block;
        z-index: 100;
    }
}




