.header {
    min-height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F6F6F6;
    padding: 0 130px;
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    transition: transform .5s;
    transform: translateY(100px);
    z-index: 9;
}

.logo_block {
    display: flex;
    align-items: center;
}

.logo {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    flex: none;
    flex-grow: 0;
    margin: 0 110px 0 15px;
    cursor: pointer;
}

.apiGuide {
    font-weight: 600;
    text-decoration: none;
    color: #000;
    font-size: 16px;
    margin: 15px 5px;
    padding: 22px;
    font-family: 'Cera Pro';
    cursor: pointer;
}

.apiGuide:hover {
    color: #EB6D35;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnWrapper {
    margin-left: 43px;
}


@media only screen
and (max-device-width: 1331px) {
    .header {
        padding:0 16px;
    }
}


@media only screen
and (max-device-width: 881px) {
    .logo {
        margin: 0 40px 0 15px;
    }
    .btnWrapper {
        margin-left: 25px;
    }

}




@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .header {
        color: #3F3F3F;
        min-height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px 11px 16px;
        position: absolute;
        width: 100%;
        background: transparent;

    }


    .apiGuide {
        display: none;
    }

    .logo {
        display: none;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        margin: 0 47px 0 15px;
    }

    .btnWrapper {
        display: none;
    }

}
