.container {
    width: 58%;
    display: flex;
    margin: 0 auto;
    padding-top: 64px;
    flex-direction: column;
}

.descBlock {
    margin-bottom: 60px;
}

.title {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #DF853B;
    margin-bottom: 10px;
}

.subtitle {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 52px;
    line-height: 55px;
    color: #3F3F3F;
    width: 50%;
}

.cardBlock {
    display: flex;
}

.card {
    width: 50%;
}

.ruCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.line {
    height: 2px;
    background-color: #3F3F3F;
    min-width: 25px;
    margin-right: 15px;
}

.cardTitle {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    margin-bottom: 25px;
    min-height: 60px;
}

.cardSubtitle {
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #797979;
    margin-bottom: 53px;
    height: 43px;
}

.ruSubtitle {
    min-height: 90px;
}

.cardTextBlock {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.cardText {
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #797979;
}


.cardTextBlockWrapper {
    min-height: 133px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {

    .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px
    }

    .container {
        width: 100%;
        text-align: center;
    }

    .cardTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
    }

    .cardBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 100%;
    }

    .cardSubtitle {
        height: auto;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .subtitle {
        width: 100%;
        font-weight: 700;
        font-size: 27px;
        line-height: 34px;
    }

    .cardText {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .descBlock {
        margin-bottom: 0;
    }

    .cardTitle {
        margin-top: 32px;
    }
}


@media only screen and (max-width: 1100px) {

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 50px;
        flex-direction: column;
        justify-content: center;
    }

    .cardBlock {
        width: 100%;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}


@media only screen and (max-width: 890px) {

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 50px;
        flex-direction: column;
        justify-content: center;
    }


    .title {
        width: 100%;
        text-align: center;
    }

    .subtitle {
        width: 100%;
        text-align: center;
    }

}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .container {
        z-index: 2;
        padding-top: 0;
        padding-left: 16px;
    }


    .title {
        width: 100%;
        text-align: left;
    }

    .subtitle {
        width: 100%;
        text-align: left;
    }


    .cardTitle {
        width: 100%;
        text-align: left;
    }

    .cardSubtitle {
        width: 100%;
        text-align: left;
    }

    .subtitle {
        width: 100%;
        text-align: left;
    }

    .cardTextBlockWrapper {
        width: 100%;
    }

}

