.loginContainer {
    display: flex;
    height: 100vh;
}

.leftSide {
    width: 50%;
    position: relative;
}

.rightSide {
    width: 50%;
    position: relative;
    background: url("../../assets/img/loginBg.png");
    background-size: cover;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 27px;
}

.logoTitle {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #3F3F3F;
    margin-left: 15px;
    cursor: pointer;
}

.formBlock {
    margin-bottom: 35px;
}

.formTitle {
    margin-bottom: 15px;
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3F3F3F;
}

.loginTitle {
    font-family: 'Cera Pro';
    font-weight: 900;
    font-size: 52px;
    line-height: 65px;
    color: #3F3F3F;
    margin-bottom: 53px;
    width: 400px;
}

.loginBlock {
    position: absolute;
    top: 25vh;
    left: 15vw;

}

.textBlock {
    width: 319px;
    position: absolute;
    top: 35vh;
    left: 25vh;
}


.back {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #F6F6F6;
    margin-right: 16px;
    position: relative;
    cursor: pointer;
}

.back:after {
    content: "";
    display: block;
    position: absolute;
    background: no-repeat url("../../assets/img/arrowBack.png");
    width: 30px;
    height: 20px;
    top: 5px;
    left: 76px;
}

.textBlockTitle {
    font-family: 'Cera Pro';
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #F6F6F6;
    margin-bottom: 35px;
}

.textBlockDesc {
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #F6F6F6;
    margin-bottom: 25px;
}

.error {
    margin-top: 20px;
    margin-bottom: 50px;
    font-family: 'Cera Pro';
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #EB5757;
}

.backMobile {
    display: none;
}

.logoMobile {
    display: none;
}

.reservedBlock {
    display: none;
}

.headerBlock {
    display: flex;
    align-items: center;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 1150px) {
    .rightSide {
        display: none;
    }

    .loginBlock {
        position: initial;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .loginContainer {
        flex-direction: column;
    }

    .loginTitle {
        font-weight: 900;
        font-size: 34px;
        line-height: 43px;
        margin-top: 65px;
        width: 100%;
    }

    .leftSide {
        width: auto;
        padding: 0 16px;
    }

    .backMobile {
        display: block;
        color: #DF853B;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        position: relative;
    }

    .logoTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        margin-left: 7px;
    }

    .backMobile:after {
        content: "";
        display: block;
        position: absolute;
        background: no-repeat url("../../assets/img/back_arrow_mobile.png");
        width: 30px;
        height: 20px;
        top: 4px;
        left: 39px;
    }

    .logoDesktop {
        display: none;
    }

    .logoMobile {
        display: block;
    }

    .header {
        justify-content: space-between;
        padding-right: 17px;
    }

    .headerBlock {
        display: flex;
        align-items: center;
    }

    .reserved {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #ABABAB;
        margin-top: 8px;
    }

    .reservedBlock {
        display: block;
        margin-bottom: 24px;
        margin-top: 122px;
    }

    .buttonWrapper {
        width: 100%;
        padding: 0 16px;
    }
}
