.App {
    font-family: cursive;
}

.title {
    text-align: center;
}

.custom-select-container {
    display: inline-block;
    min-width: 288px;
    height: 55px;
    text-align: center;
    position: relative;
}

.selected-text {
    text-align: left;
    height: 55px;
    background-color: #fff;
    padding: 18px 25px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    font-family: 'Cera Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #D9D9D9;
}

.selected-text.my_active {
    color: #000;
}

.selected-text::after {
    content: url("/src/assets/img/dropdown_arrow.png");
    position: absolute;
    right: 25px;
    top: 18px;
    color: #000;
    transition: .5s all ease;
    transform: rotate(-180deg);
}

.selected-text.my_active::after {
    top: 18px;
    transform: rotate(0deg);
    transition: .5s all ease;
}

ul {
    margin-top: 5px;
    padding: 0;
    text-align: center;
    border-radius: 28px;
}

.select-options {
    position: absolute;
    width: 100%;
    border-radius: 28px;
}

li {
    list-style-type: none;
    padding: 10px 20px;
    color: #ABABAB;
    background: #fff;
    cursor: pointer;
    text-align: left;
}

li:first-child {
    border-radius: 28px 28px 0 0;
}

li:last-child {
    border-radius: 0 0 28px 28px;
}

li:hover {
    color: #3F3F3F;
}
